import { getAndSetAllCustomers, getAndSetClientContacts } from "@/services/hooks";
import { useCallback, useEffect, useState } from "react";
import { useGomakeAxios } from "./use-gomake-axios";
import { useDebouncedCallback } from "use-debounce";

const useCustomerDropDownList = () => {
  const { callApi } = useGomakeAxios();
  const [customersListCreateQuote, setCustomersListCreateQuote] = useState([]);
  const [supplierList, setSupplierList] = useState([]);
  const [clientContactsValue, setClientContactsValue] = useState<any>([]);
  const [customer, setCustomer] = useState<{
    name: string;
    id: string;
  } | null>();

  const renderOptions = () => {
    return customersListCreateQuote;
  };

  const getAllCustomersCreateQuote = useCallback(async (SearchTerm?) => {
    await getAndSetAllCustomers(callApi, setCustomersListCreateQuote, {
      ClientType: "C",
      searchTerm: SearchTerm,
      onlyCreateOrderClients: false,
    });
  }, []);

  const getAllCustomersCreateOrder = useCallback(async (SearchTerm?) => {
    await getAndSetAllCustomers(callApi, setCustomersListCreateQuote, {
      ClientType: "C",
      searchTerm: SearchTerm,
      onlyCreateOrderClients: true,
    });
  }, []);

  const getAllSupplierList = useCallback(async (SearchTerm?) => {
    await getAndSetAllCustomers(callApi, setSupplierList, {
      ClientType: "S",
      searchTerm: SearchTerm,
      onlyCreateOrderClients: true,
    });
  }, []);

  const debouncedGetAllCustomersCreateOrder = useDebouncedCallback((value) => {
    getAllCustomersCreateOrder(value);
  }, 500);

  const checkWhatRenderArray = (e) => {
    const value = e.target.value;
    if (value) {
      debouncedGetAllCustomersCreateOrder(value);
    } else {
      getAllCustomersCreateQuote();
    }
  };
  useEffect(()=>{
    getAllSupplierList()
  },[])

  const handleCustomerChange = (e: any, value: any) => {
    setCustomer(value);
  };

  useEffect(() => {
    getAllCustomersCreateQuote();
  }, []);

  const getAllClientContacts = useCallback(async () => {
    await getAndSetClientContacts(callApi, setClientContactsValue, {
      ClientId: customer?.id,
    });
  }, [customer]);

  return {
    customer,
    getAllCustomersCreateQuote,
    renderOptions,
    checkWhatRenderArray,
    handleCustomerChange,
    getAllClientContacts,
    setCustomer,
    clientContactsValue,
    supplierList
  };
};

export { useCustomerDropDownList };
